<template>
  <b-card-actions action-collapse title="Liste des Produits">
    <div class="custom-search d-flex justify-content-end">
      <b-form-group>
        <div class="d-flex align-items-center">
          <label class="mr-1">Recherche</label>
          <b-form-input
            placeholder="Recherche"
            type="text"
            class="d-inline-block"
            @input="advanceSearch"
          />
        </div>
      </b-form-group>
    </div>

    <vue-good-table
      ref="table-category"
      :columns="columns"
      :rows="rows"
      :rtl="direction"
      :search-options="{
        enabled: true,
        externalQuery: searchTerm,
      }"
      :pagination-options="{
        enabled: true,
        perPage: pageLength,
        //  mode: 'records'
      }"
      theme="my-theme"
    >
      <!--<template
        slot="table-row"
        slot-scope="props"
      >
        <span
          v-if="props.column.label === 'Start date'"
          class="text-nowrap"
        >
        <span>
           {{props.row.datedeb | moment("YYYY-MM-DD")}}
        </span>
        </span>
        <span
          v-else-if="props.column.label === 'End date'"
          class="text-nowrap"
        >
        <span>
           {{props.row.datedeb | moment("add", props.row.duration+" days","YYYY-MM-DD")}}
        </span>
        </span>
     
  </template>-->

      <template slot="table-row" slot-scope="props">
        <span v-if="props.column.label === 'Category'" class="text-nowrap">
          {{ props.row.categories[0] }}
        </span>
        <span v-else-if="props.column.label === 'Product'" class="text-nowrap">
          {{ props.row.products[0].name }}
        </span>
        <span v-else-if="props.column.label === 'Status'" class="text-nowrap">
          {{ props.row.state }}
        </span>
        <span v-else-if="props.column.label === 'Actions'" class="text-nowrap">
          <span>
            <b-dropdown
              variant="link"
              toggle-class="text-decoration-none"
              no-caret
            >
              <template v-slot:button-content>
                <feather-icon
                  icon="MoreVerticalIcon"
                  size="16"
                  class="text-body align-middle mr-25"
                />
              </template>

              <b-dropdown-item @click="validatestatus(props.row)">
                <feather-icon icon="TrashIcon" class="mr-50" />
                <span>Validated</span>
              </b-dropdown-item>
              <b-dropdown-item @click="rejectstatus(props.row)">
                <feather-icon icon="TrashIcon" class="mr-50" />
                <span>Rejected</span>
              </b-dropdown-item>
            </b-dropdown>
          </span>
        </span>
      </template>
      <!-- pagination -->
      <template slot="pagination-bottom" slot-scope="props">
        <div class="d-flex justify-content-between flex-wrap">
          <div class="d-flex align-items-center mb-0 mt-1">
            <span class="text-nowrap"> Afficher 1 à </span>
            <b-form-select
              v-model="pageLength"
              :options="['3', '5', '10']"
              class="mx-1"
              @input="
                (value) => props.perPageChanged({ currentPerPage: value })
              "
            />
            <span class="text-nowrap"> sur {{ props.total }} résultats </span>
          </div>
          <div>
            <b-pagination
              :value="1"
              :total-rows="props.total"
              :per-page="pageLength"
              first-number
              last-number
              align="right"
              prev-class="prev-item"
              next-class="next-item"
              class="mt-1 mb-0"
              @input="(value) => props.pageChanged({ currentPage: value })"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </div>
        </div>
      </template>
    </vue-good-table>
  </b-card-actions>
</template>

<script>
import "vue-good-table/dist/vue-good-table.css";
import { VueGoodTable } from "vue-good-table";
import productBoostMixin from "@/mixins/boostProducts.mixin";
import BCardActions from "@core/components/b-card-actions/BCardActions.vue";

import {
  BCard,
  BCardHeader,
  BCardBody,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BDropdown,
  BDropdownItem,
  BModal,
  VBModal,
  BAlert,
  BFormGroup,
  BListGroup,
  BListGroupItem,
  BFormInvalidFeedback,
  BInputGroup,
  BInputGroupPrepend,
  BCardText,
  VBTooltip,
  BPagination,
  BFormSelect,
  BForm,
  BTooltip,
} from "bootstrap-vue";
export default {
  mixins: [productBoostMixin],

  components: {
    VueGoodTable,
    BCard,
    BCardHeader,
    BCardBody,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BDropdown,
    BDropdownItem,
    BModal,
    VBModal,
    BAlert,
    BFormGroup,
    BListGroup,
    BListGroupItem,
    BCardActions,
    BFormInvalidFeedback,
    BInputGroup,
    BInputGroupPrepend,
    BCardText,
    VBTooltip,
    BPagination,
    BFormSelect,
    BForm,
    BTooltip,
  },

  data() {
    return {
      pageLength: 5,
      searchTerm: "",
      columns: [
        {
          label: "User",
          field: "user.fullName",
        },
        {
          label: "Product",
          field: "products",
        },
        {
          label: "Category",
          field: "categories",
        },

        {
          label: "Duration",
          field: "nbOfDays",
        },
        {
          label: "Price DT",
          field: "price",
        },
        {
          label: "Status",
          field: "status",
        },
        {
          label: "Actions",
          field: "status",
        },
      ],
      rows: [
        {
          _id: 1,
          nbOfDays: 5,
          price: 20,
          categories: ["cat"],
          products: [
            {
              id: 1,
              name: "p1",
            },
          ],
          state: "created",
          user: {
            _id: 1,
            fullName: "aziz",
            email: "aziz@gmail.com",
          },
        },
      ],
    };
  },

  mounted() {
    // rows = this.productBoosts
  },

  watch: {
    productBoosts: function () {
      // rows = this.productBoosts
    },
  },

  methods: {
    advanceSearch(val) {
      this.searchTerm = val;
    },
    validatestatus(item) {
      item.status = "validated";
      this.$store.dispatch("UpdateUserBoost", item).then((data) => {});
    },
    rejectstatus(item) {
      item.status = "rejected";
      this.$store.dispatch("UpdateUserBoost", item).then((data) => {});
    },
  },
};
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-good-table.scss";
</style>